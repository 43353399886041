<template>
  <div class="peppes-product" :class="[`size-${size}`, `order-${order}`]">
    <div class="peppes-product-image-container">
      <NuxtImg
        class="peppes-product-image"
        :src="product.img"
        :placeholder="loadingBox"
        :alt="product.name"
        loading="eager"
        @click="handleProductClicked"
      >
        <template #error>
          <img :src="loadingBox" :alt="product.name" class="fallback-image" />
        </template>
      </NuxtImg>
      <div v-if="showActions" class="peppes-product-actions">
        <div class="peppes-product-action action-add">
          <IconsAddButtonIcon @click="handleProductClicked" />
        </div>
      </div>
      <div v-if="product.tag" class="peppes-product-tag">
        <Tag :value="product.tag" :class="product.tag === 'Popular' ? 'popular' : 'new'"></Tag>
      </div>
    </div>
    <div class="peppes-product-details" @click="handleProductClicked">
      <div class="peppes-product-title">
        <div class="title-text">
          <span class="product-number">{{
            product.name.split(".").length === 2 ? product.name.split(".")[0] + "." : ""
          }}</span>
          {{ product.name.split(".")[1] ?? product.name }}
        </div>
        <div class="product-attributes">
          <div v-for="(attribute, index) in attributes" :key="index" class="product-attribute">
            <component :is="attributesComponents[attribute]" />
          </div>
        </div>
      </div>
      <div class="peppes-product-description">
        {{ product.isDeal && product.dealInfo ? product.dealInfo.description : product.dsc }}
      </div>
      <div class="peppes-product-price">
        <span class="regular-price"> {{ product.price }},- </span>
        <!--        <span v-if="!!product?.discount" class="new-price">-->
        <!--          {{ product.price - product.discount }}-->
        <!--        </span>-->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconsSpicyX2Icon from "~/components/icons/SpicyX2Icon.vue";
import IconsSpicyX3Icon from "~/components/icons/SpicyX3Icon.vue";
import IconsVegIcon from "~/components/icons/VegIcon.vue";
import loadingBox from "~/assets/logo/loading_box.svg";

import { type ProductAttributeType, type ProductEntity } from "~/types/entities/product.type";
import { useProductsStore } from "~/store/productSlice";

const productSlice = useProductsStore();
const attributesComponents = shallowRef({
  Veg: IconsVegIcon,
  SpicyX2: IconsSpicyX2Icon,
  SpicyX3: IconsSpicyX3Icon,
});

const props = defineProps({
  order: {
    type: String as PropType<"row" | "col">,
    default: "row",
  },
  product: {
    type: Object as PropType<ProductEntity>,
    default: () => ({}),
  },
  size: {
    type: String as PropType<"sm" | "lg">,
    default: "lg",
  },
  showActions: {
    type: Boolean,
    default: false,
  },
  attributes: {
    type: Array as PropType<Array<ProductAttributeType>>,
    default: () => [],
  },
});
const handleProductClicked = () => {
  if (!props.product.isDeal) {
    productSlice.toggleProductOverviewModal(props.product);
  } else {
    productSlice.toggleDealModal(props.product);
  }
};
</script>
